import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import user from "../../api/user";
import FormikFrom from "../../components/elements/form/FormikFrom";
import InputField from "../../components/elements/form/InputField";
import InputText from "../../components/elements/form/InputText";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import RadioButton from "../../components/elements/form/RadioButton";
import SelectBox from "../../components/elements/form/SelectBox";
import AddressList from "../address/AddressList";
import CartParameter from "../cart/CartParameter";
import LocalstorageParam from "../common/LocalstorageParam";

/*
  To set local storage parameter in json
*/
var localparam = LocalstorageParam();

var countryarray = [];
var statearray = [];
var countrycodearray = [];

/*
  To set form initial values
*/
const initialValues = {
  fullname: "",
  addressphonecountry: "",
  addressphonenumber: "",
  country: "",
  state: "",
  city: "",
  pincode: "",
  companyname: "",
  address: "",
  addresstype: "",
  isdefaultaddress: "",
  id: "",
};

/*
  To validation address form using yup validation
*/
const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required("Please enter value for Fullname")
    .matches(/^[^\s][a-zA-Z '.-]*$/, "Please enter valid Fullname"),
  addressphonenumber: Yup.string()
    .matches(/^[0-9]+$/, "Please enter valid mobile no")
    .required("Please enter value for Mobile No")
    .min(10, "Please enter minimum 10 digits Mobile no"),
  addressphonecountry: Yup.string().required("Please enter value for Country Code"),
  state: Yup.string().required("Please enter value for State"),
  city: Yup.string()
    .matches(/[^\s*]/g, 'White Space is not allowed')
    .required("Please enter value for City"),
  pincode: Yup.string()
    .required("Please enter value for Pincode")
    .matches(/^[0-9]+$/, "Please enter valid Pincode")
    .min(6, "Please enter valid digit Pincode"),
  companyname: Yup.string().nullable()
    .matches(/[^\s*]/g, 'White Space is not allowed'),
  address: Yup.string()
    .matches(/[^\s*]/g, 'White Space is not allowed')
    .required("Please enter value for Address"),
  country: Yup.string().required("Please enter value for Country "),
});

const MyAddress = ({
  ecomerce,
  addressevent,
  sethandelisdefault,
  countrylist,
  setAddressValues,
  displaydelete,
  sameasshipping,
}) => {
  /*
    To set url paramere in json
  */
  var searchparam = CartParameter();
  const navigate = useNavigate();

  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState([]);
  const [address, setAddress] = useState([]);
  const [isdefaultaddress, setIsDefaultAddress] = useState(0);
  const [addresstype, setAddressType] = useState(["Home"]);
  const [edit, setEdit] = useState(false);
  const [selectedcountry, setSelectedcountry] = useState();
  const [selectedstate, setSelectedstate] = useState();
  const [selectedcountrycode, setSelectedCountryCode] = useState();
  const [isdisable, setIsDisable] = useState(false);
  const [iscountrylist, setCountryList] = useState(countrylist);
  const [CompanyName, setCompanyName] = useState("");
  const [ErrorMsg, setErrorMsg] = useState("");

  var addressdetail;

  /*
    To colse address model and remove class from body
  */
  const handleClose = () => {
    setShow(false);
    document.body.classList.add("overflow-auto");
  };

  /*
    To open address model form
  */
  const openAddressModel = (event) => {
    setShow(true);
    document.body.classList.remove("overflow-auto");
    if (event !== "Edit") {
      addressdetail = "";
      setAddressInFormik(initialValues);
      initialValues.addresstype = "Home";
      setAddressType("Home");
      setState([]);
      setSelectedcountry("");
      setSelectedstate("");
      setSelectedCountryCode("");
      setIsDisable(false);
      handleInputChange(false);
      setIsDefaultAddress(0);
      if (document.getElementById("adddresstitle")) {
        document.getElementById("adddresstitle").innerHTML = "Add Address";
      }
    } else {
      if (document.getElementById("adddresstitle")) {
        document.getElementById("adddresstitle").innerHTML = "Edit Address";
      }
    }
  };

  useEffect(() => {
    if (
      localparam !== "" &&
      localparam.decoded !== "" &&
      localparam.decoded.userid !== null &&
      localparam.decoded.userid !== ""
    ) {
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
      }, 500);
      setCountryInArray();
      getAddressList();
    } else {
      navigate("/login");
    }

    window.scrollTo(0, 0);

  }, [ecomerce, isdefaultaddress, edit, countrylist]);

  useEffect(() => {
    if (selectedcountry) {
      setState([]);
      setSelectedstate([]);
    }
  }, [])

  /*
    To set country in array and object
  */
  const setCountryInArray = () => {
    if (countrylist && countrylist.length > 0) {
      countryarray = [];
      countrycodearray = [];
      countrylist.map((item) => {
        var countryobject = new Object();
        countryobject["value"] = item.id;
        countryobject["label"] = item.name;
        countryarray.push(countryobject);

        var countrycodeobject = new Object();
        countrycodeobject["value"] = item.extension;
        countrycodeobject["label"] = item.extension;
        countrycodearray.push(countrycodeobject);
      });
    }
  };

  /*
    To get State by country id and set in array
  */
  const getState = async (countryid, addressdetail) => {
    if (countryid && countryid !== null && countryid !== "") {
      setSelectedstate([]);
      setState([]);

      const res = await user.getState(countryid);
      if (res.data.status === 200) {
        setState(res.data.payload.states);
        statearray = [];
        res.data.payload.states.map((item) => {
          var stateobject = new Object();
          stateobject["value"] = item.id;
          stateobject["label"] = item.name;
          statearray.push(stateobject);
        });

        initialValues.state = "";

        if (addressdetail) {
          var list = statearray.find((item) => Number(item.value) === Number(addressdetail.state));
          if (list) {
            setSelectedstate(list);
            initialValues.state = list.value;
          }
        }
      }
    }
  };

  /*
    To save address
  */
  const saveAddress = async (data) => {
    setDisable(true);

    data.isdefaultaddress = isdefaultaddress;
    if (data.addresstype === null || data.addresstype === "") {
      data.addresstype = "Home";
    }
    if (CompanyName) {
      data.companyname = CompanyName;
    }
    const res = await user.saveAddress(data);
    if (res.data.status === 200) {
      setDisable(false);
      setTimeout(() => {
        setDisable(false);
        if (addressevent === "checkout") {
          window.location.href = "/checkout?coupon=";
        } else {
          window.location.href = "/myaddress";
        }
      }, 500);
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = res.data.errors.message;
    }
  };

  /*
    To get all address list of user or get address data by address id
  */
  const getAddressList = async (addressid) => {
    if (!addressid) {
      addressid = "";
    }
    var addressobject = new Object();
    if (addressid) {
      addressobject["addressid"] = addressid;
    }
    const res = await user.getAddress(addressobject);
    if (res.data.status === 200) {
      if (addressid && addressid !== null && addressid !== "") {
        addressdetail = res.data.payload[0];
        setAddressInFormik(initialValues);
        getState(addressdetail.country, addressdetail);
        setEdit(true);
        openAddressModel("Edit");
        if (address && address.length <= 1) {
          if (addressdetail.isdefaultaddress !== 1) {
            setIsDisable(true);
            handleInputChange(true);
          }
        }
      } else {
        setAddress(res.data.payload);
        if (res.data.payload.length <= 0) {
          setIsDisable(true);
          handleInputChange(true);
        }
      }
    }
  };

  /*
    To set value in formik form
  */
  const setAddressInFormik = (formikvalue) => {
    formikvalue.fullname = addressdetail.fullname;
    formikvalue.country = addressdetail.country;
    formikvalue.state = addressdetail.state;
    formikvalue.addressphonecountry = addressdetail.addressphonecountry;
    formikvalue.addressphonenumber = addressdetail.addressphonenumber;
    formikvalue.city = addressdetail.city;
    formikvalue.pincode = addressdetail.pincode;
    formikvalue.companyname = addressdetail.companyname;
    formikvalue.address = addressdetail.address;
    formikvalue.id = addressdetail.id;
    if (addressdetail.isdefaultaddress) {
      formikvalue.isdefaultaddress = addressdetail.isdefaultaddress;
      setIsDefaultAddress(Number(addressdetail.isdefaultaddress));
    } else {
      formikvalue.isdefaultaddress = 0;
      setIsDefaultAddress(Number(0));
    }
    formikvalue.addresstype = addressdetail.addresstype;

    setAddressType(addressdetail.addresstype);
    setCompanyName(addressdetail.companyname);

    if (countrylist && countrylist.length > 0) {
      var list = countryarray.find((item) => Number(item.value) === Number(addressdetail.country));
      if (list) {
        setSelectedcountry(list);
      }

      var list = countrycodearray.find((item) => Number(item.value) === Number(addressdetail.addressphonecountry));
      if (list) {
        setSelectedCountryCode(list);
      }
    }
  };

  /*
    To set default address
  */
  const handleInputChange = (val) => {
    initialValues.isdefaultaddress = val;
    setIsDefaultAddress(0);
    if (val) {
      setIsDefaultAddress(1);
    }
  };

  /*
    To update address
  */
  const updateAddress = async (data) => {
    setDisable(true);

    data.isdefaultaddress = isdefaultaddress;
    data.companyname = CompanyName;

    const res = await user.updateAddress(data);
    if (res.data.status === 200) {
      setDisable(false);
      setTimeout(() => {
        setDisable(false);
        if (addressevent === "checkout") {
          window.location.href = "/checkout?coupon=";
        } else {
          window.location.href = "/myaddress";
        }
      }, 500);
    } else {
      setDisable(false);
      document.getElementById("errormsg").classList.remove("hide");
      document.getElementById("errormsg").innerHTML = res.data.errors.message;
    }
  };

  /*
    To set address type in state variable 
  */
  const handleChangeAddress = (val) => {
    initialValues.addresstype = val;
    setAddressType(val);
  };

  return (
    <div className="ps-page__content">
      <GoogleCaptcha />
      <div className="ps-form--review">
        <div className="row">
          <div className="col-12 col-md-6 align-self-center">
            <h2 className="ps-form__title mb-0">{addressevent === "checkout" ? "Shipping Address" : "My Address"}</h2>
          </div>
          <div className="col-12 col-md-6 add-address">
            <button className="warningbtn width-120" onClick={(e) => openAddressModel("")}>
              Add
            </button>
            <div>
              <Modal show={show} onHide={handleClose} className="modal-width" size={"lg"} id="addressform">
                <Modal.Header closeButton className="pt-15 pb-15">
                  <h2 className="ps-form__title font-size-24 mb-0" id="adddresstitle">
                    {initialValues.id ? "Edit Address" : "Add Address"}
                  </h2>
                </Modal.Header>
                <FormikFrom
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    {
                      values.addresstype = addresstype;
                      initialValues.id ? updateAddress(values) : saveAddress(values);
                    }
                  }}
                >
                  <Form>
                    <Modal.Body>
                      <div className="ps-form--review modelbody padding-left-30 padding-right-30" id="modelbody">
                        <div className="row mt-20">
                          <div className="col-12 col-md-6">
                            <InputField
                              label={"Full Name *"}
                              name={"fullname"}
                              type={"text"}
                              placeholder={"Full Name"}
                              maxLength={"50"}
                              defaultValue={initialValues.fullname}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <SelectBox
                              onChange={setErrorMsg}
                              label={"Country *"}
                              name={"country"}
                              options={countryarray}
                              placeholder={"Country"}
                              setSelectedCountry={setSelectedcountry}
                              initialValues={initialValues}
                              value={selectedcountry}
                              getState={(countryid) => getState(countryid)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <SelectBox
                              label={"State *"}
                              name={"state"}
                              options={statearray}
                              placeholder={"State"}
                              setSelectedCountry={setSelectedstate}
                              initialValues={initialValues}
                              value={selectedstate}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <InputField
                              label={"City *"}
                              name={"city"}
                              type={"text"}
                              placeholder={"City"}
                              maxLength={"50"}
                              defaultValue={initialValues.city}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <InputField
                              label={"Pincode *"}
                              name={"pincode"}
                              type={"text"}
                              placeholder={"Pincode"}
                              maxLength={"6"}
                              defaultValue={initialValues.pincode}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <InputText
                              label={"Company Name"}
                              name={"companyname"}
                              type={"text"}
                              placeholder={"Company Name"}
                              maxLength={"50"}
                              onChange={(e) => setCompanyName(e.target.value)}
                              defaultValue={CompanyName}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <InputField
                              label={"Address *"}
                              name={"address"}
                              placeholder={"Address"}
                              maxLength={"500"}
                              component="textarea"
                              defaultValue={initialValues.address}
                              classname="ps-form__textarea"
                              rows="3"
                              id={"address"}
                              onScrollDown={"yes"}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="row">
                              <div className="col-12 col-md-5">
                                <SelectBox
                                  label={"Country *"}
                                  name={"addressphonecountry"}
                                  options={countrycodearray}
                                  placeholder={"Country"}
                                  setSelectedCountry={setSelectedCountryCode}
                                  initialValues={initialValues}
                                  value={selectedcountrycode}
                                  id="addressphonecountry"
                                  onScrollDown={"yes"}
                                />
                              </div>
                              <div className="col-12 col-md-7 ">
                                <InputField
                                  label={"Mobile No *"}
                                  name={"addressphonenumber"}
                                  type={"text"}
                                  placeholder={"Mobile No"}
                                  maxLength={"10"}
                                  id="addressphonenumber"
                                  defaultValue={initialValues.addressphonenumber}
                                  onScrollDown={"yes"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="ps-form__group form-group address-radio">
                              <div className="input-group margin-top-40">
                                <Field
                                  component={RadioButton}
                                  name="addresstype"
                                  id="home"
                                  label="Home"
                                  className="form-control  radio-button"
                                  checked={addresstype == "Home" ? true : false}
                                  onChange={() => handleChangeAddress("Home")}
                                />
                                <Field
                                  component={RadioButton}
                                  name="addresstype"
                                  id="office"
                                  label="Office"
                                  className="form-control  radio-button"
                                  checked={addresstype == "Office" ? true : false}
                                  onChange={() => handleChangeAddress("Office")}
                                />
                              </div>
                              <ErrorMessage name="addresstype" component="span" className="color-red" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="ps-form__group form-group">
                              <div className="input-group margin-top-40">
                                <div className="form-check ml-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="isdefaultaddress"
                                    name="isdefaultaddress"
                                    checked={isdefaultaddress == 1 ? true : false}
                                    onChange={(e) => handleInputChange(e.target.checked)}
                                    value={isdefaultaddress}
                                    disabled={isdisable}
                                  />
                                  <label className="form-check-label" htmlFor="isdefaultaddress">
                                    Save this as Default Address
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="color-red hide pl-15 pt-10" id="errormsg"></span>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="submit"
                        className={disable ? "ps-btn ps-btn--warning disabled" : "ps-btn ps-btn--warning "}
                      >
                        {initialValues.id ? "Update" : "Save"}
                      </button>
                      <Button variant="secondary" className="ps-btn ps-btn--warning" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Form>
                </FormikFrom>
              </Modal>
            </div>
          </div>
          <div className="col-12 col-md-12 pt-30">
            <div className="row">
              <AddressList
                address={address}
                getAddressList={getAddressList}
                addressevent={addressevent}
                setIsDefaultAddress={setIsDefaultAddress}
                sethandelisdefault={sethandelisdefault}
                setAddressValues={setAddressValues}
                displaydelete={displaydelete}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state)(MyAddress);
