import React from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";

import config from "../config/config.json";
import { calculateProductPriceOffer } from "../utilities/product-helper";
import { currencyPrice } from "./currencyCalculation";

/*
  To set image in variable with image path
*/
function getImageURL(source) {
  let imageURL = "/static/img/noproduct-images.png";
  let imagepath = config.apiUrl + config.image_path;
  if (source.variantimagename) {
    imageURL = `${imagepath}${source.variantimagename}`;
  } else if (source.imagename) {
    imageURL = `${imagepath}${source.imagename}`;
  }
  return imageURL;
}

export default function useProduct() {
  return {
    thumbnailImages: (payload) => {
      if (payload) {
        if (payload) {
          return (
            <>
              <LazyLoad>
                <img src={getImageURL(payload)} alt="" />
              </LazyLoad>
              <LazyLoad>
                <img src={getImageURL(payload)} className="second" alt="" />
              </LazyLoad>
            </>
          );
        }
      }
    },
    price: (payload, currencyrate, event) => {
      let view;
      if (payload) {
        if (payload.sale_price) {
          view = (
            <p className="ps-product__price sale price-font">
              {currencyPrice(payload.sale_price, currencyrate)}
              <del className="ml-2">{currencyPrice(payload.price, currencyrate)}</del>
            </p>
          );
        } else {
          view = (
            <div>
              <p className="ps-product__price price-font mb-0" id={`price_${payload.id}`}>
                {payload.offerprice && Number(payload.offerprice) > 0 ? (
                  <>
                    <span>{currencyPrice(payload.offerprice, currencyrate)}</span>
                    <span className="text-line-through color-lightblue pl-10">
                      {currencyPrice(payload.price, currencyrate)}
                    </span>
                    <span className="price-percentage">({calculateProductPriceOffer(payload)} %)</span>
                  </>
                ) : (
                  <>{currencyPrice(payload.price, currencyrate)}</>
                )}
              </p>
              <p className="ps-product__price price-font hide mb-0" id={"after_discount_" + payload.id}>
                <span id={"discount_price_" + payload.id}> {currencyPrice(payload.price, currencyrate)}</span>
                <span className="text-line-through color-lightblue pl-10" id={`mainprice_${payload.id}`}>
                  {currencyPrice(payload.price, currencyrate)}
                </span>
                <span className="price-percentage hide" id={"offer_percentage_" + payload.id}></span>
              </p>
              {event === "productdetail" && <p className="font-size-12 pt-10">[Inclusive of tax]</p>}
            </div>
          );
        }
      }

      return view;
    },
    badges: (payload) => {
      let view = null;
      if (payload.badges && payload.badges.length > 0) {
        const items = payload.badges.map((item) => {
          if (item.value === "hot") {
            return (
              <span className="ps-product__badge hot" key={item.id}>
                Hot
              </span>
            );
          }
          if (item.value === "new") {
            return (
              <span className="ps-product__badge new" key={item.id}>
                New
              </span>
            );
          }
          if (item.value === "sale") {
            return (
              <span className="ps-product__badge sale" key={item.id}>
                Sale
              </span>
            );
          }
        });
        view = <div className="ps-product__badges">{items}</div>;
      }
      return view;
    },
    onSale: (payload) => {
      let view = null;
      if (payload.sale_price && payload.on_sale) {
        const discountPercent = (((payload.sale_price - payload.price) / payload.price) * 100).toFixed(0);
        view = <span className="ps-product__on-sale">-{discountPercent}%</span>;
      }
      return view;
    },
    brand: (payload) => {
      let view;
      if (payload.product_brands && payload.product_brands.length > 0) {
        view = (
          <Link to={"/shop"} className="text-capitalize">
            {payload.product_brands[0].name}
          </Link>
        );
      } else {
        view = (
          <Link to={"/shop"} className="text-capitalize">
            No Brand
          </Link>
        );
      }
      return view;
    },
  };
}
