import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import useGetProducts from "../../../../hooks/useGetProducts";
import { UrlProduct } from "../../../../pages/product/LoadProduct";
import ModuleShopPaginationRange from "../../../partials/shop/modules/ModuleShopPaginationRange";
import ModuleShopSortBy from "../../../partials/shop/modules/ModuleShopSortBy";
import SidebarShop from "../../../shared/sidebar/SidebarShop";

/*
  To layout items json
*/
const layoutItems = [
  {
    id: 1,
    url: "&layout=grid&columns=3",
    image: "/static/img/icon/gird3.svg",
    imageActive: "/static/img/icon/gird3.svg",
  },
  {
    id: 2,
    url: "&layout=grid&columns=4",
    image: "/static/img/icon/gird4.svg",
    imageActive: "/static/img/icon/gird4.svg",
  },
];

const ModuleShopActions = ({ setIsMenu, isMenu, currencyrate }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filtergroup, getFilterGroup } = useGetProducts();
  const [selectedLayout, setSelectedLayout] = useState();
  const { category, getCategory } = useGetProducts();

  const navigate = useNavigate();

  /*
    To open menu in mobile
  */
  function handleOpenMenu(e) {
    e.preventDefault();
    setIsMenu(true);
  }

  /*
    To close menu in mobile
  */
  function handleCloseMenu(e) {
    e.preventDefault();
    setIsMenu(false);
  }

  /*
    To set url
  */
  let layout = searchParams.get("layout");
  let columns = searchParams.get("columns");

  let url = "&layout=grid&columns=4";

  useEffect(() => {
    selecteLayout(url);
    getFilterGroup();
    getCategory(searchParams.get("parentcategory"));
  }, [url]);

  /*
    To dispaly menu active
  */
  const selecteLayout = (url) => {
    if (layout && layout !== null && layout !== "") {
      url = "&layout=" + layout;
      if (columns && columns !== null && columns !== "") {
        url = url + "&columns=" + columns;
      }
    }
    layoutItems.map((item) => {
      if (document.getElementById(item.url)) {
        document.getElementById(item.url).classList.remove("active");
      }
    });

    if (document.getElementById(url)) {
      document.getElementById(url).classList.add("active");
    }
    setSelectedLayout(url);
  };

  /*
    To handle selecte layout
  */
  function handleSelecteLayout(e, layout) {
    e.preventDefault();
    setSelectedLayout(layout.url);
    navigate(UrlProduct(searchParams, "layout") + layout.url);
  }

  /*
    To view
  */
  const swichersItemsView = layoutItems.map((item) => (
    <a
      className={`ps-shop__layout-switcher ${item.url === selectedLayout ? "active" : ""}`}
      onClick={(e) => handleSelecteLayout(e, item)}
      key={item.id}
      id={item.url}>
      <img src={item.image} alt={item.image} />
    </a>
  ));

  return (
    <div className="ps-shop__actions shopaction-filter ">
      <div className="ps-shop__actions-left mobile-hide">
        <div className="ps-shop__layout-switchers">{swichersItemsView}</div>
      </div>
      <div className="ps-shop__actions-right filter-right">
        <a className="mobile-show color-blue filtericon pb-2" onClick={(e) => handleOpenMenu(e)}>
          <i className="fa fa-filter"></i>
        </a>
        <ModuleShopSortBy />
        <ModuleShopPaginationRange />
      </div>

      {isMenu ? (
        <Drawer
          className="ps-panel--mobile mobile-home"
          closable={false}
          placement="left"
          onClose={(e) => handleCloseMenu(e)}
          visible={isMenu}>
          <div className="ps-drawer ps-drawer--with-menu">
            <div className="ps-drawer__header pull-right">
              <a href="#" className="ps-drawer__close" onClick={(e) => handleCloseMenu(e)}>
                <i className="icon-cross"></i>
              </a>
            </div>
            <div className="ps-drawer__wrapper pt-50">
              <div className="ps-drawer__menu">
                <SidebarShop filtergroup={filtergroup} currencyrate={currencyrate} category={category} />
              </div>
            </div>
          </div>
        </Drawer>
      ) : (
        ""
      )}

      {/* </MobileView> */}
    </div>
  );
};

export default ModuleShopActions;
