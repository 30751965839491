import React, { useEffect, useState } from 'react';
import axios from 'axios';

import config from '../../config/config.json';
import GoogleCaptcha from '../../components/common/GoogleCaptcha';
import Container from '../../components/layouts/Container';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../components/elements/BreadCrumb';

const Page = () => {
  const { id } = useParams();

  const [pagedetail, setPageDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  const headers = {
    Accept: "*/*",
  };
  const pagename = pagedetail.map(item => item.pagename);

  /*
   To breadcrumb
 */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "Page",
      url: "/pages",
    },
    {
      id: 3,
      text: pagename,
    }
  ]

  /*
    To get page by id
  */
  const getPageId = async (slug) => {
    setLoading(true);
    try {
      const url = `${config.apiUrl}${config.apiurlname}/page/${slug}`;
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setPageDetail(response.data.payload.data);
      } else {
        setPageDetail({});
      }
    } catch (error) {
      setPageDetail({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPageId(id);
  }, [id]);

  return (
    <>
      <Container>
        <GoogleCaptcha />
        <div className="form-loader">
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
          <div className="ps-page">
            <div className="container">
              <div className="ps-page__header pb-0">
                <BreadCrumb breacrumb={breadcrumb} />
              </div>
              <div className="ps-page__content mb-25">
                <div className='page-content'>
                  {pagedetail && pagedetail.map(item =>
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Page;